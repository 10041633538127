<template>
  <div>
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center subtitle-4 black--text">الموظفين</h1>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="table.headers"
            loading-text="جاري التحميل ... الرجاء الانتظار"
            :items="table.Data"
            :options.sync="tableOptions"
            :server-items-length="table.totalData"
            :loading="table.loading"
            class="elevation-1"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }"
          >
            <template v-slot:item.num="{ item }">
              {{ table.Data.indexOf(item) + 1 }}
            </template>
            <template slot="item.year" slot-scope="props">
              <p>{{props.item._id.year }}</p>
            </template>
            <template slot="item.month" slot-scope="props">
              <p>{{props.item._id.month }}</p>
            </template>
            <template slot="item.allMoney" slot-scope="props">
              <v-chip color="primary" dark>{{ numberWithComma(props.item.allMoney) }}</v-chip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
        <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import employeeApi from '@/api/employee'
import numberWithComma from '@/constant/number'
export default {
  data() {
    return {
      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },
      id: this.$route.params.id,

      allData: [],
      tableOptions: {},

      table: {
        search: null,
        totalData: 0,
        Data: [],
        loading: true,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: 'num',
            width: 1,
          },
          { text: 'السنة', sortable: false, value: '_id.year' },
          { text: 'الشهر', sortable: false, value: '_id.month' },
          { text: 'المبلغ', sortable: false, value: 'allMoney' },
          { text: 'عدد الفواتير', sortable: false, value: 'number_of_salary' },
        ],
      },
    }
  },
  created() {
    this.getDataAxios()
  },
  methods: {
    async getDataAxios() {
      this.table.loading = true

      const response = await employeeApi.getEmployeeProfile(this.id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.table.loading = false
        this.table.Data = response.data.results
        this.table.totalData = response.data.length
      }
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    numberWithComma,
  },
}
</script>